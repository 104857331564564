import logo from './logo.svg';
import './App.css';
import Lottie from 'react-lottie-player'
import lottieJson from './animation.json'
import thl from './THL logo2.png'

function App() {
  return (
    <div className="App">  <div style={{width:'100%',paddingTop:'14px',paddingBottom:'10px'}}> 
    
    </div>
      <header className="App-header">
        <img src={thl} style={{width:'320px'}}></img>
   <br/>
      <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: 350, height: 350 }}
    />    
      <br/>  
    <div className='container'>
        <p style={{color:'#445b95',fontSize:'16px'}}> 
      We’re working behind the scenes to bring you a brand-new website where we showcase our advanced technical solutions. </p>
       <p style={{color:'#445b95',fontSize:'16px'}}> Stay tuned as we finalise this transformation. We appreciate your patience! </p>
       <p style={{color:'#445b95',fontSize:'16px'}}> Feel free to reach out to us at <a>post@thlabs.eu.</a> </p>
       
        </div>
      </header>
    </div>
  );
}

export default App;

